<script lang="ts">
    import { _ } from "svelte-i18n";
    import { ItemWithLabel } from "@two-ui/core";
    import { faClockRotateLeft, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
    import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

    import { type TradeAccountUI, formatDate } from "./helpers";
    import { LABEL_TO_VALUE_MAP } from "./variables";

    export let accountDetails: TradeAccountUI | null = null;

    export const accountDetailsList = [
        { label: LABEL_TO_VALUE_MAP($_).legalName, value: accountDetails?.legalName },
        { label: LABEL_TO_VALUE_MAP($_).accountCreatedOn, value: accountDetails?.createdAt },
        { label: LABEL_TO_VALUE_MAP($_).shippingAddress, value: accountDetails?.shippingAddress },
        { label: "", value: "" },
        {
            label: LABEL_TO_VALUE_MAP($_).verification,
            value: accountDetails?.verificationType,
            icon: accountDetails.verificationTime ? faCircleCheck : faCircleXmark,
            iconColor: accountDetails.verificationTime
                ? "var(--ctnSemanticSuccessPrimary)"
                : "var(--ctnInteractiveGeneralDisabled)",
            secondary: accountDetails.verificationTime
                ? formatDate(accountDetails.verificationTime)
                : null,
            secondaryIcon: faClockRotateLeft
        }
    ];
</script>

<section class="flex flex-wrap items">
    {#each accountDetailsList as item}
        <div class="w-1/2 mb-4 item-wrap">
            <ItemWithLabel
                label={item.label}
                value={item.value}
                icon={item.icon}
                iconColor={item.iconColor}
                secondary={item.secondary}
                secondaryIcon={item.secondaryIcon}
            />
        </div>
    {/each}
</section>

<style>
    .items .item-wrap:nth-child(odd) {
        padding-right: 1rem;
    }
    .items .item-wrap:nth-child(even) {
        padding-left: 1rem;
    }
</style>
