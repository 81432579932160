<script lang="ts">
    import { _ } from "svelte-i18n";

    import Selector from "@/components/Elements/Selector.svelte";
    import NewWayToPayModal from "./NewWayToPayModal.svelte";
    import { account } from "@/store/merchant/account.store";
    import { orderReplica } from "@/store/merchant/allOrders.store";
    import { selectedPaymentMethod } from "@/store/merchant/orderCreate.store";
    import {
        paymentMethods,
        netTermsOptions,
        instalmentOptions,
        STANDARD_TERMS,
        INSTALMENT_PLAN
    } from "@/utilsV2/netTerms";

    const numberOfInstallmentsFromReplica: number =
        $orderReplica?.terms?.number_of_instalments || 0;
    let paymentMethod: string = numberOfInstallmentsFromReplica
        ? INSTALMENT_PLAN($_)
        : STANDARD_TERMS($_);
    let showModal: boolean = false;

    export let payment_terms: string = "";
    export let invalidDueInDays = "";

    $: {
        selectedPaymentMethod.set(paymentMethod);
    }
    // reset payment terms when payment method changed
    $: if (
        (paymentMethod === STANDARD_TERMS($_) && instalmentOptions($_).includes(payment_terms)) ||
        (paymentMethod === INSTALMENT_PLAN($_) && netTermsOptions($_).includes(payment_terms))
    ) {
        payment_terms = "";
    }

    $: {
        if (
            paymentMethod === INSTALMENT_PLAN($_) &&
            !$account.flags.enable_instalment_order_creation
        ) {
            showModal = true;
        }
    }

    function closeModal() {
        showModal = false;
        paymentMethod = STANDARD_TERMS($_);
    }
</script>

<section>
    <div class="flex items-center gap-1">
        <div class="text-Content-General-Primary font-bold">
            {$_("order.new.limits.repaymentMethod")}
        </div>
    </div>
    <div class="mb-4 mt-1 text-Content-General-Secondary">
        {$_("order.new.limits.choosePaymentMethod")}
    </div>
    <div class="flex gap-[1rem]">
        <div class="w-full mt-3 pr-2">
            <Selector
                id="payment_method"
                label={$_("order.new.limits.paymentMethod")}
                required
                showArrow={true}
                options={paymentMethods($_)}
                bind:value={paymentMethod}
                placeholder="-"
                size="44px"
                backgroundColor="white"
                showInvalidError={true}
            />
        </div>
    </div>
    <div class="flex pt-2 gap-[1rem]">
        <div class="w-full mt-3 pr-2" class:with-error={invalidDueInDays}>
            <Selector
                id="invoice_payment_terms"
                label={$_("order.new.limits.repaymentTerms")}
                required
                showArrow={true}
                options={paymentMethod === INSTALMENT_PLAN($_)
                    ? instalmentOptions($_)
                    : netTermsOptions($_)}
                bind:value={payment_terms}
                placeholder="-"
                size="44px"
                backgroundColor="white"
                showInvalidError={true}
                invalid={invalidDueInDays}
            />
        </div>
    </div>
    <NewWayToPayModal bind:showModal on:close={closeModal} />
</section>
