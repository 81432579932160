<script lang="ts">
    import { onMount } from "svelte";
    import { navigate } from "svelte-routing";

    import { checkIfMobile } from "@/utils/methods";
    import {
        actions as accountActions,
        account,
        signupComplete,
        getAccountExecuted,
        loggedInAsMerchant,
        loggedInAsMerchantPortalUser
    } from "@/store/merchant/account.store";
    import SideNav from "@/components/sidenav/SideNav.svelte";
    import { getMerchantItemList } from "@/static/itemList";
    import type { IAccount } from "@/static/types";
    import merchantRoutes from "@/routes/merchant.routes";

    import allOrdersState from "@/store/merchant/allOrders.store";
    import modalState from "@/store/modals.store";
    import { statements } from "@/store/merchant/billingStatement.store";
    import Router from "@/routes/CustomRouter.svelte";
    import { WELCOME_TILLIT } from "@/static/modals";
    import ChatSupport from "@/components/chatSupport/ChatSupport.svelte";
    import environment from "@/services/environment";

    export let url: string;

    let merchantItemList = [];
    let hidden: boolean = false;
    let show: boolean = true;
    $: merchantLoggedIn = $loggedInAsMerchant;
    $: userLoggedIn = $loggedInAsMerchantPortalUser;
    const ifMobile: boolean = checkIfMobile();

    const close = (): any => {
        show = false;
        hidden = true;
    };

    $: if (merchantLoggedIn === true) {
        const redirect = localStorage.getItem("redirect");
        if (redirect) {
            localStorage.removeItem("redirect");
            navigate(redirect);
        }
    } else if (userLoggedIn === true) {
        navigate("/auth/merchant/user/select");
    } else if (userLoggedIn === false && merchantLoggedIn === false) {
        localStorage.setItem("redirect", window.location.pathname);
        navigate("/auth/merchant/login");
    }

    $: account.subscribe((value: IAccount) => {
        const context = {
            countryCode: value.countryCode,
            shortName: value.short_name,
            partnerShortName: value.partner_short_name,
            isShowPlans: value?.flags?.enabled_leasing_instalments_api
        };
        merchantItemList = getMerchantItemList(context);

        if (merchantLoggedIn && $signupComplete && $getAccountExecuted) {
            allOrdersState.actions.getAllOrders();
            statements.actions.getStatements();
            accountActions.getMerchantRecourseLimit();
        }
    });

    onMount(() => {
        accountActions.getAccount();
    });

    $: if (!$signupComplete) modalState.actions.setModal(WELCOME_TILLIT, {});
</script>

<!-- Zendesk Chat Widget -->
{#if environment.branding?.hasSupportChat}
    <ChatSupport />
{/if}
<!-- End Zendesk Chat Widget -->

{#if merchantLoggedIn !== false}
    <div class="relative w-full">
        <div class="flex flex-row w-full h-content">
            <SideNav itemList={merchantItemList} {hidden} {show} on:close={close} />
            <div class="w-full relative right-container" class:w-containment={ifMobile}>
                <div style="h-0 sm:h-5" />
                <Router routes={merchantRoutes} />
            </div>
        </div>
    </div>
{/if}

<style>
    .w-containment {
        min-width: 1250px;
    }

    .right-container {
        background-color: var(--bgGeneralTertiary);
    }
</style>
