import type { paymentProductType } from "./constant";
import type countryName from "./countryName";

type CountryCode = keyof typeof countryName;

type InputType = "text" | "number" | "email" | "dropdown" | "tel" | "image";

enum CurrencyCodeEnum {
    USD = "USD",
    EUR = "EUR",
    SEK = "SEK",
    NOK = "NOK",
    GBP = "GBP",
    DKK = "DKK",
    ZZZ = "ZZZ" // unknown
}

export type CurrencyCode = keyof typeof CurrencyCodeEnum;

enum LanguageCode {
    EN = "en",
    NB = "nb",
    SV = "sv",
    US = "en-us",
    NL = "nl",
    FI = "fi"
}

enum status {
    UNVERIFIED,
    REFUNDED,
    CANCELLED,
    VERIFIED,
    FULFILLED
}

type IOrderStatus = keyof typeof status;
interface IOrder {
    selected?: boolean;
    orderID?: string;
    merchantOrderID?: string;
    merchantName?: string;
    merchant?: string;
    user?: string;
    purDate?: string;
    invDate?: string;
    dueDate?: string;
    amount?: string;
    status: IOrderStatus;
}

type IBMorder = IMorder & IOrder;

interface IMorder {
    selected?: boolean;
    orderID?: string;
    merchantOrderID?: string;
    status: IOrderStatus;
    payable_amount?: number;
    comName?: string;
    userName?: string;
    purDate?: Date;
    fulDate?: Date;
    amount?: number;
    settlement?: string;
    currency?: string;
    download_url?: string;
    credit_note_download_url?: string;
    invoice_type?: string;
    invoice_details?: IInvoice;
    billing_statement_id?: string;
    buyerCountryCode?: CountryCode;
}

interface IBorder {
    selected?: boolean;
    orderID?: string;
    merchantOrderID?: string;
    merchantName?: string;
    userName: string;
    purDate?: Date;
    fulDate?: Date;
    amount?: number;
    dueDate?: Date;
    currency?: CurrencyCode;
    download_url?: string;
    status?: IOrderStatus;
    payable_amount?: number;
    payment_status?: string;
    due_term?: number;
    invoice_details?: IInvoice;
    buyerCompany?: string;
}

interface INavItem {
    icon: string;
    name: string;
    url: string;
    margin: string;
    show: boolean;
    subItems?: INavItem[];
}

export type Address = {
    city: string;
    country: string;
    id: string;
    organization_name: string;
    postal_code: string;
    preferred: boolean | null;
    region: string;
    street_address: string;
};

export type TradeAccount = {
    approved_for_credit: boolean;
    billing_email_address: string;
    buyer_is_in_arrears: boolean | null;
    country_prefix: string;
    date_created: Date;
    credit_limit: number | null;
    credit_limit_balance: number | null;
    credit_limit_currency: CurrencyCode;
    decline_reason: string;
    email_domain: string;
    estimated_monthly_spend: number | null;
    id: string;
    legal_name: string;
    trade_name: string | null;
    max_single_order_value: number | null;
    merchant_id: string;
    official_address: Address;
    organization_id: string;
    shipping_addresses: Address[];
    verifications: Array<{
        date_created: Date;
        verification_type:
            | "EMAIL"
            | "SMS"
            | "VIPPS_LOGIN"
            | "BANKID"
            | "SKIP"
            | "YAPILY"
            | "VERIFIED_BY_MERCHANT"
            | "ONFIDO"
            | "BASIS"
            | "I_DIN";
        verified: boolean;
        verified_on: string;
    }>;
};

export type BillingAccount = {
    account_name: string;
    buyer_company: {
        country_code: string;
        legal_name: string | null;
        national_identification_number: string | null;
    };
    last_accepted_order_date: string;
    cc_email_addresses: string[];
    currency: CurrencyCode;
    due_in_days: number;
    generation_delay_days: number;
    id: string;
    phone_number: string | null;
    primary_email_addresses: string[];
    schedule: string;
    last_accepted_order_date: string;
    last_completed_period_end_date: string;
    last_completed_period_id: string;
    last_completed_period_start_date: string;
    last_statement_issue_date: string;
};

interface IIssue {
    id?: string;
    by?: string;
    date?: string;
    amount?: string;
}

interface IPaid {
    id?: string;
    date?: string;
    by?: string;
    buyer_org?: string;
    buyer_org_id?: string;
    on_behalf_of?: string;
    org_id?: string;
}

interface IDelivery {
    id?: string;
    placed_date?: string;
    notes?: string;
    d_address?: string[];
}
interface IPayment {
    article_number?: string;
    img_url?: string;
    item?: string;
    status?: string;
    date?: string;
    price_per_unit?: string;
    unit_number?: number;
    total_price?: string;
    vat?: string;
    incl_vat?: string;
    discount?: string;
}

interface IGroupedStatement {
    buyer_company_name: string;
    end_date: string;
    payment_reference: string;
    payment_status: string;
    start_date: string;
    statement_id: string;
    statement_number: number;
    total_amount: string;
}

interface IQuote {
    id?: string;
    quoteId: string;
    status?: string;
    buyer_company_name?: string;
    buyer_representative?: string;
    buyerCountryCode?: CountryCode;
    issue_date?: Date;
    accepted_date?: Date;
    total_amount?: string;
    currency?: string;
    orderSummary: IMorderSummary | null;
}

interface IAllOrders {
    id?: string;
    order_status?: string;
    amount?: string;
    buyer_company?: string;
    buyer_country?: string;
    buyer_email?: string;
    buyer_phone_number?: string;
    buyer_representative?: string;
    currency?: CurrencyCode;
    fulfill_date?: Date;
    invoice_number?: string;
    invoice_type?: string;
    last_refund_date?: Date;
    order_date?: Date;
    order_number?: string;
    order_state?: string;
    order_type?: string;
    refunded_amount?: string;
    display_status?: string;
    is_latent?: boolean;
    action_required?: string;
    payment_status?: string;
    invoice_due_date?: Date;
    fulfilled_amount?: string;
    approved_on_recourse?: boolean;
}

interface ITransaction {
    selected?: boolean;
    transactionID?: string;
    buyer: string;
    date: string;
    item: string[];
    value: string;
    status?: string;
    reference?: string;
    settled?: string;
}

interface IPaymentList {
    id?: string;
    payments?: IPayment[];
}

interface ILogin {
    email: string;
    password: string;
}

interface IAuthUser {
    email: string;
    isConfirmEmail: boolean;
}

enum NotificationType {
    SUCCESS = "SUCCESS",
    ERROR = "ERROR",
    WARNING = "WARNING"
}

interface INotification {
    id: string;
    type: NotificationType;
    message: string;
    extraInfo?: string;
    displayWithItem?: string;
    tryAgainActionModal?: object | string;
    tryAgainActionProps?: object;
}

interface IBanner {
    type: NotificationType;
    header?: string;
    body?: string;
    textLink?: string;
    closed?: boolean;
}

interface IUsers {
    url: string;
    firstName: string;
    lastName: string;
    id: string;
    status: string;
    department: string;
    pcLimit: string;
    leftSpend: string;
    amount: number;
}

enum sortType {
    "up",
    "down"
}

type ISort = keyof typeof sortType;

interface IMorderDetail {
    width: number;
    imgUrl: string;
    title: string;
}

interface IFrame {
    imgLink: string;
    alt: string;
    hour: string;
    pluginLink: string;
    guideLink: string;
}

interface IBuyerDetails {
    companyName: string;
    companyNumber: string;
    countryCode: CountryCode;
}

interface IMorderSummary {
    download_url?: string;
    credit_note_download_url?: string;
    currency?: CurrencyCode;
    orderID?: string;
    merchantOrderID?: string;
    merchantShortName?: string;
    merchantOrderReference?: string;
    merchantAdditionalInfo?: string;
    createDate?: string;
    updateDate?: string;
    fulfillDate?: string;
    state?: OrderState;
    payable_amount?: number;
    notes?: string;
    companyName?: string;
    buyerName?: string;
    country?: CountryCode;
    buyerDepartment?: string;
    companyID?: string;
    phone?: string;
    email?: string;
    shippingAddress?: string[];
    originalShippingAddress?: Record<string, string>;
    shippingCompanyName?: string;
    billingAddress?: string[];
    shippingComp?: string;
    trackingNum?: string;
    dueDate?: string;
    invoiceDueDate?: string;
    during?: string;
    amount?: string;
    grossAmount?: string;
    bankAmount?: string;
    bankAccount?: string;
    KIDNum?: string;
    invoiceNumber?: string;
    payment_status?: string;
    status?: string;
    accountType?: string;
    lineItems?: IOrderItem[];
    refunds?: IRefund[];
    delivery?: string;
    invoice_type?: InvoiceType;
    incurredFees?: string;
    percentageFee?: string;
    fixedFee?: string;
    type?: OrderType;
    purchaseOrderNumber?: string;
    emailForInvoice?: string;
    project?: string;
    invoice_details?: IInvoice;
    terms?: {
        number_of_instalments: number;
        period: "MONTHLY";
    };
    tax_amount?: string;
    discount_amount?: string;
    origination?: OrderOrigination;
    rootOrderID?: string;
    approvedOnRecourse?: boolean;
    declineReason?: string;
    invoiceEmails?: string;
    features?: string[];
}

interface IRefundSurcharge {
    amount: number;
    surcharge_type?: string;
    tax_rate: number;
}

interface IRefund {
    amount?: string;
    credit_note_url?: string;
    currency?: string;
    external_refund_id?: string;
    id?: string;
    initiate_payment_to_buyer?: boolean;
    line_items: IOrderItem[];
    reason?: string;
    refund_no?: number;
    tax_subtotals?: ITaxSubtotal[];
    buyer_refund_surcharges?: IRefundSurcharge[];
}

interface ITaxSubtotal {
    tax_amount: number;
    taxable_amount: number;
    tax_rate: string;
}

enum OrderState {
    CANCELLED = "CANCELLED",
    FULFILLED = "FULFILLED",
    FULFILLING = "FULFILLING",
    PART_FULFILLED = "PART-FULFILLED",
    DELIVERED = "DELIVERED",
    PARTIAL_REFUND = "PARTIAL_REFUND",
    PENDING = "PENDING",
    CONFIRMED = "CONFIRMED",
    VERIFIED = "VERIFIED",
    REFUNDED = "REFUNDED",
    UNVERIFIED = "UNVERIFIED",
    UNINITIATED = "UNINITIATED",
    READY_TO_FULFILL = "READY TO FULFILL"
}

enum OrderType {
    BUYER_INITIATED = "BUYER_INITIATED",
    MERCHANT_INITIATED = "MERCHANT_INITIATED"
}

enum OrderOrigination {
    ONLINE = "ONLINE",
    IN_STORE = "IN_STORE"
}

enum InvoiceType {
    FUNDED = "FUNDED_INVOICE",
    DIRECT = "DIRECT_INVOICE"
}

interface IOrderItem {
    id: string;
    name: string;
    description: string;
    discount_amount: number;
    gross_amount: number;
    net_amount: number;
    quantity: number;
    unit_price: number;
    tax_amount: number;
    tax_rate: number;
    tax_class_name: string;
    quantity_unit: string;
    image_url?: string;
    product_page_url?: string;
    type: string;
}

interface IBorderSummary {
    download_url?: string;
    currency?: CurrencyCode;
    orderID?: string;
    merchantOrderID?: string;
    merchantName?: string;
    buyerDepartment?: string;
    createDate?: string;
    updateDate?: string;
    state?: string;
    payable_amount?: number;
    notes?: string;
    companyName?: string;
    buyerName?: string;
    buyerUrl?: string;
    companyID?: string;
    phone?: string;
    email?: string;
    shippingAddress?: string[];
    shippingCompanyName?: string;
    billingAddress?: string[];
    shippingComp?: string;
    trackingNum?: string;
    dueDate?: string;
    during?: string;
    amount?: string;
    bankAmount?: string;
    bankAccount?: string;
    KIDNum?: string;
    invoiceNumber?: string;
    status?: string;
    accountType?: string;
    lineItems?: IPayment[];
    delivery?: string;
    orgId?: string;
    paymentStatus?: string;
    accountNo?: string;
    sortCode?: string;
    paymentReference?: string;
    bban?: string;
}

interface IInvoice {
    id: string;
    due_date: string;
    due_in_days: number;
    invoice_addess: string;
    invoice_issueDate: string;
    invoice_number: string;
    payee: IPayee;
    payment_reference: string;
    payment_reference_message: string;
    payment_reference_ocr: string;
    payment_reference_type: string;
    payment_status: string;
    invoice_emails: Array<string>;
    settlement_status?: string;
    value?: string;
    invoice_url?: string;
    order_id?: string;
    invoice_type?: string;
}

interface IPayee {
    bban: string;
    bic: string;
    branch_sort_code: string;
    country_code: string;
    description: string;
    iban: string;
    id: string;
    kid_type: string;
    local_account_number: string;
    organization_name: string;
    organization_number: string;
}

//customer for customer page in merchant portal
interface ICustomer {
    selected: boolean;
    buyer_organization_number: string;
    buyer_company_name: string;
    buyer_country_code?: CountryCode;
    due_in_days: number;
    additional_credit: boolean;
    limit: number;
    unit: string;
    total_gross_amount: string;
    total_order_count: number;
    direct_invoice_fallback: boolean;
    fallback_threshold: string;
    billing_email_addresses: string[];
    credit_limit_balance?: string;
    credit_limit?: string;
    on_recourse?: boolean;
    recourse_max_amount?: string;
    current_recourse_exposure?: string;
    max_merchant_buyer_recourse_limit?: string;
    max_two_buyer_recourse_limit?: string;
    merchant_buyer_recourse_exposure?: string;
    two_buyer_recourse_exposure?: string;
    remaining_buyer_recourse_limit?: string;
    remaining_merchant_buyer_recourse_limit?: string;
    remaining_two_buyer_recourse_limit?: string;
}

interface ICustomerRecourseLimit {
    id?: string;
    buyer_organization_number: string;
    buyer_country_code?: CountryCode;
    currency: CurrencyCode;
    max_amount: string;
    merchant_id?: string;
    buyer_available_recourse_limit?: string;
    current_recourse_exposure?: string;
    merchant_buyer_available_recourse_limit?: string;
    merchant_buyer_recourse_exposure?: string;
    remaining_recourse_limit?: string;
    merchant_buyer_recourse_limit?: string;
}

interface IRepaymentPlanCustomer {
    trade_name?: string;
    country_code?: string;
    email?: string;
}

interface IRepaymentPlan {
    id?: string;
    created_ts?: Date;
    reference?: string;
    summary_status?: string;
    credit_status?: string;
    customer?: IRepaymentPlanCustomer;
    funded_entity?: string;
    on_recourse?: boolean;
    total_repayment_amount?: string;
    invoiced_amount?: string;
}

export interface IBuyerCountryConfiguration {
    country_code: string;
    available_currencies: string[];
}

interface IContactDetail {
    name?: string;
    phone?: string;
    email?: string;
}

interface IPayoutAccount {
    bank_country_code: string;
    bban?: string;
    branch_sort_code?: string;
    currency: string;
    local_account_number?: string;
    merchant_id?: string;
    merchant_payout_account_alias?: string;
    payout_plan?: string;
    recipient_name?: string;
    sale_country_code?: string;
}

export interface IDocument {
    date_issued?: Date;
    download_link?: string;
    name?: string;
    status?: string;
    document_type?: string;
}

interface IMerchantFlags {
    cross_border_enabled?: boolean;
    buyer_fallback_enabled?: boolean;
    bank_statement_email_is_enabled?: boolean;
    has_made_order?: boolean;
    webhooks_enabled?: boolean;
    show_line_item_more_details_on_order_creator?: boolean;
    show_settlement_payout_customized_for_kredinor_on_portal?: boolean;
    legacy_settlement_ui_portal?: boolean;
    show_new_order_details_page?: boolean;
    enable_fe_partial_fulfillment?: boolean;
    enable_invoice_editing?: boolean;
    enable_instalment_order_creation?: boolean;
    enable_fe_validate_customer_recourse_limit?: boolean;
    api_key_management_enabled?: boolean;
    merchant_initiated_enabled?: boolean;
    fetch_only_leaf_orders?: boolean;
    fraud_check_on_order_creation: boolean;
    enable_create_order_via_oc: boolean;
    enabled_leasing_instalments_api: boolean;
    provides_own_invoice_numbers: boolean;
    has_billing_accounts: boolean;
    business_registration_button_enabled?: boolean;
}

//account interface for account page in merchant portal
interface IAccount {
    accountName?: string;
    displayName?: string;
    legalName?: string;
    nationalID?: string;
    countryCode?: CountryCode;
    email?: string;
    emailInvoice?: string;
    emailErp?: string;
    phone?: string;
    phoneInvoice?: string;
    website?: string;
    registeredAddress?: string;
    formattedRegisterdAddress?: string;
    bic?: string;
    iban?: string;
    bban?: string;
    description?: string;
    feeCurrency?: string;
    payoutPlan?: string;
    transactionFee?: string;
    standardTerms?: string;
    fixedFee?: string;
    logoPath?: string;
    organizationName?: string;
    organizationNumber?: string;
    sortCode?: string;
    localAccountNumber?: string;
    requestedFinancing?: boolean;
    isOnboardedMerchant?: boolean;
    short_name?: string;
    partner_short_name?: string;
    bank_statement_email?: string;
    bank_statement_format?: string;
    merchant_user?: object;
    admin_user?: object;
    is_missing_user_info?: boolean;
    buyer_country_configurations: IBuyerCountryConfiguration[];
    documents: IDocument[];
    fraud_contact: IContactDetail;
    payout_accounts: IPayoutAccount[];
    flags: IMerchantFlags;
    merchantCountry?: ICountry;
}

interface ISignupAccount extends IAccount {
    application_id: string;
    first_name: string;
    last_name: string;
    vat_number?: string;
    address?: IAddress;
}

interface IAddress {
    streetAddress: string;
    city: string;
    postalCode: string;
}

interface IBuyerLanguage {
    region: CountryCode;
    buyerLanguage?: string;
}

interface IStatement {
    selected?: boolean;
    id?: string;
    date_payout?: string;
    fee?: string;
    download_url_xlsx?: string;
    fulfilled_amount?: string;
    net_payout?: string;
    net_payin?: string;
    number_of_entries?: number;
    payout_pending?: string;
    payin_pending?: string;
    refunded_amount?: string;
}

interface ICreditInsights {
    buyer_company_id: string;
    buyer_organization_number: string;
    buyer_company_name: string;
    buyer_country_code: string;
    credit_limit?: string;
    credit_limit_available?: string;
    credit_limit_currency?: string;
    credit_limit_provider?: string;
    credit_limit_used_percentage?: string;
    total_order_spend_last_30_days?: string;
}

interface ICreditAlert {
    email_addresses: string[];
    last_sent_by?: string;
    last_sent_at?: Date;
}

interface IAuth {
    data: object;
    phoneNumber: string;
    isValidPhone: boolean;
}

interface IVerificationConfig {
    riskThreshold?: string;
    verificationThreshold?: number;
    minOrderValue?: number;
    maxOrderValue?: number;
    level0Verification?: number;
    smsVerificationThreshold?: number;
    extraVerificationThreshold?: number;
    level3Verification?: number;
    show: boolean;
}

interface IFeaturesList {
    title?: string;
    details?: string;
}

enum paymentStatus {
    "NOT_PAID",
    "PAID",
    "PARTIAL_PAYMENT",
    "OVERDUE",
    "IN_COLLECTIONS",
    "CANCELLED",
    "PARTIAL_REFUND",
    "REFUNDED"
}

type IMOrderPaymentStatus = keyof typeof paymentStatus;

enum userRoles {
    "Admin",
    "Developer",
    "Accountant",
    "User"
}

type IMUserRoles = keyof typeof userRoles;

interface ITeamUser {
    id?: string;
    firstName?: string;
    lastName?: string;
    team?: string;
    phoneNumber?: string;
    email?: string;
    role?: string;
    status?: string;
}

interface IMNewUserConfig {
    first_name?: string;
    last_name?: string;
    phone_number?: string;
    email?: string;
    team?: string;
    role?: IMUserRoles | string;
}

interface IDashboardChartValue {
    amount: string;
    end_time: string;
    start_time: string;
    currency: CurrencyCode;
}

interface IMarket {
    countryCode: CountryCode;
    currency: CurrencyCode;
}

interface IDashboardOrderStateData {
    average_order_value?: string;
    incr_average_order_value?: string | number;
    incr_order_count?: string | number;
    incr_total_value?: string | number;
    list_state_total?: IDashboardChartValue[];
    list_state_volume?: IDashboardChartValue[];
    order_count?: number;
    percentage_of_approved_orders?: string;
    total_value?: string;
}

interface MerchantPeriodBuyerOverview {
    unique_buyer_count: number;
    buyer_conversion_rate: number;
    buyer_credit_approval_rate: number;
    orders_fulfilled_rate: number;
}

interface IMerchantPeriodFulfilledOverviewResponseSchema {
    total_fulfilled_order_refund_value: number;
    total_fulfilled_order_value: number;
    total_fulfilled_order_value_increase: number | string;
    total_fulfilled_order_count: number;
    total_fulfilled_order_count_increase: number | string;
    average_fulfilled_order_value: number;
    average_fulfilled_order_value_increase: number | string;
    currency: CurrencyCode;
}

interface IMerchantPeriodReportGraphData {
    fulfilled_order_currency_totals: [IDashboardChartValue];
    refunded_order_currency_totals: [IDashboardChartValue];
    fulfilled_order_eur_totals: [IDashboardChartValue];
    refunded_order_eur_totals: [IDashboardChartValue];
    fulfilled_order_volumes: [IDashboardChartValue];
}

interface IDashboardData {
    start_date?: Date;
    end_date?: Date;
    buyer_overview?: MerchantPeriodBuyerOverview;
    fulfilled_overview?: IMerchantPeriodFulfilledOverviewResponseSchema;
    report_graph_data?: IMerchantPeriodReportGraphData;
    currencies_used?: [];
}

interface B2bOnboardingVerifyEmailScreenType {
    heading?: string;
    subheading?: string;
    submissionText?: string;
    termsLink?: string;
}

interface B2bOnboardingCustomerScreenType {
    heading?: string;
    subheading?: string;
    submissionText?: string;
    termsLink?: string;
}

interface B2bOnboardingCompanyScreenType {
    heading?: string;
    subheading?: string;
}

interface MerchantB2bOnboardingSuccess {
    icon?: string;
    heading?: string;
    details?: string;
    success_url?: string;
}

interface MerchantB2bOnboardingFailure {
    icon?: string;
    heading?: string;
    details?: string;
    failure_url?: string;
}

interface MerchantB2bOnboardingTokenExpired {
    icon?: string;
    heading?: string;
}

interface MerchantB2bOnboardingSkipVerification {
    content?: string;
    buttonText?: string;
}

interface MerchantB2bOnboardingData {
    logo_url?: string;
    description?: string;
    two_logo_label?: string;
    billing_address_guidelines?: string;
    company_screen?: B2bOnboardingCompanyScreenType;
    customer_screen?: B2bOnboardingCustomerScreenType;
    verify_email_screen?: B2bOnboardingVerifyEmailScreenType;
    success?: MerchantB2bOnboardingSuccess;
    failure?: MerchantB2bOnboardingFailure;
    tokenExpired?: MerchantB2bOnboardingTokenExpired;
    skipEmailVerification?: MerchantB2bOnboardingSkipVerification;
}

interface IPaymentDetails {
    payment_amount?: number;
    payee_name?: string;
    payment_reference?: string;
    product_type?: paymentProductType;
    product_no?: string;
    payment_already_successful?: boolean;
    latest_update_time?: string;
}

interface PaymentBankDetails {
    account_number?: string;
    sort_code?: string;
    payment_reference?: string;
    account_name?: string;
    iban?: string;
    bban?: string;
    bic?: string;
}

interface IPaymentInitiationPaymentDetails extends IPaymentDetails {
    payee_account?: PaymentBankDetails;
    billing_start_date?: string;
    billing_end_date?: string;
    merchant_name?: string;
    merchant_partner?: string;
    buyer_name?: string;
}

interface IPaymentInitiationPaymentResponse {
    payment_details?: IPaymentDetails;
    yapily_payment_id?: string;
    payment_already_successful?: boolean;
}

interface IPaymentAuthorisationResponse {
    authorization_url?: string;
    qr_code_url?: string;
    authorization_id?: string;
}

interface ICountry {
    name?: string;
    country_code?: CountryCode;
    currency?: CurrencyCode;
    languageCode?: LanguageCode;
    language?: string;
    phoneCountryCode?: string;
    buyerCurrencies?: string[];
}

interface ICreditLimit {
    buyerCompanyName: string;
    buyerCompanyNumber: string;
    buyerCountryCode?: string;
    creditLimit?: number;
    availableCreditLimit?: number;
    maxSingleOrderValue?: number;
    isBuyerInArrears?: boolean;
    buyerFallbackEnabled: boolean;
    fallbackThreshold: string;
    credit_approved: boolean;
    additional_data_required?: string;
    recourseLimits?: string;
    declineReason?: string;
}

export interface ICombinedCreditLimit {
    additional_data_required: null;
    combined_available_credit: number;
    combined_credit_limit: number;
    combined_last_updated: string; // ISO date string
    company_id: string; // UUID
    country_prefix: string;
    currency: string;
    decline_reason: null;
    detailed_decision: null;
    eh_available_credit: number;
    eh_credit_limit: number;
    eh_internal_id: null | string;
    eh_last_updated: null | string;
    id: string; // UUID
    long_term_available_credit: number;
    long_term_credit_limit: number;
    long_term_max_order_value: number;
    max_merchant_buyer_recourse_limit: number;
    max_order_value: number;
    max_two_buyer_recourse_limit: number;
    merchant_buyer_recourse_exposure: number;
    merchant_id: string; // UUID
    merchant_short_name: string;
    organization_number: string;
    override_available_credit: null | number;
    override_credit_limit: null | number;
    override_max_order_value: null | number;
    remaining_buyer_recourse_limit: number;
    remaining_merchant_buyer_recourse_limit: number;
    remaining_two_buyer_recourse_limit: number;
    two_buyer_recourse_exposure: number;
    two_last_updated: string; // ISO date string
}

interface ICreditLimitResult {
    success: boolean;
    creditLimit: ICreditLimit;
}

interface IUpdateEmailStatementSettings {
    bank_statement_email: string;
    bank_statement_email_is_enabled: boolean;
    bank_statement_format: string;
}

interface IAccountFieldImage {
    label: string;
    name: string;
    value: string;
    editable?: boolean;
    inputType?: InputType;
    logoPath: string;
    required?: boolean;
    placeholder?: string;
}
interface IAccountField {
    label: string;
    name: string;
    value: string;
    editable?: boolean;
    inputType?: InputType;
    tooltipHint?: string;
    minLength?: number;
    maxLength?: number;
    icon: string;
    placeholder?: string;
    nullAllowed?: boolean;
    noEditIcons?: boolean;
    selectorInput?: ISelectorInputField;
    parentNode?: object;
    childNodeValueName?: string;
    required?: boolean;
}

interface ISelectorInputField {
    placeholder?: string;
    options: Map<string | number, string>;
    labelLines?: string[];
}

interface IGridSection {
    gridTitle?: string;
    gridFields: Record<string, string>[];
}

interface IAccountSectionField {
    title?: string;
    label?: string;
    type?: InputType;
    key?: string;
    value?: string | number;
    placeholder?: string;
    explanatory_text?: string;
    required?: boolean;
    editable?: boolean;
    gridSections?: Array<IGridSection>;
    selectorInput?: ISelectorInputField;
    // used for editable fields to keep track of update attempts
    interactionId?: string;
}

interface IAccountSection {
    title?: string;
    subtitle?: string;
    fields?: Array<IAccountSectionField>;
    documents?: Array<IDocument>;
}

interface IMerchantOrderInvalidLineItems {
    itemName: string;
    unitPrice: string;
    quantity: string;
    discount?: string;
    taxRate: string;
    barcode: string;
}

interface IMerchantRecourseLimit {
    id?: string;
    merchant_id?: string;
    max_amount: number;
    recourse_fallback_enabled: boolean;
    currency: CurrencyCodeEnum;
}

interface IMerchantRemainingRecourseLimit {
    remaining_recourse_limit: number;
    currency: CurrencyCodeEnum;
}

interface IMerchantUnreconciledInvoiceAmount {
    total_exposure_on_recourse: number;
    currency: CurrencyCodeEnum;
}

// Keeping this interface to support backward compatibility while BE deployment is done
// TODO (hpatel) Remove once BE is deployed!
interface IMerchantUnreconciledInvoiceAmountOutdated {
    total_unreconciled_recourse_invoice_amount: number;
    currency: CurrencyCodeEnum;
}

interface IMerchantRecourseLimitConfig {
    merchant_recourse_limits: IMerchantRecourseLimit[];
    merchant_remaining_recourse_limit?: IMerchantRemainingRecourseLimit[];
    merchant_recourse_exposure?: IMerchantUnreconciledInvoiceAmount[];
    unreconciled_invoice_amounts?: IMerchantUnreconciledInvoiceAmountOutdated[];
}
export interface IBillingAccountBuyerStatementResponse {
    merchant_company_name: string;
    merchant_website: string;
    billing_statement_id: string;
    billing_statement_number: string;
    billing_statement_type: string;
    statement_date: Date; // Maybe string
    due_date: Date; // Maybe string
    gross_amount: number;
    currency: CurrencyCodeEnum;
    unreconciled_amount: number;
    statement_pis_link: string;
    trade_name_list: string[];
    billing_account_id: string;
    billing_period_id: string;
}

export interface IBuyerMerchantRelationship {
    merchant_id: string;
    merchant_display_name?: string;
    merchant_address?: string;
    buyer_company_name?: string;
    buyer_organization_number?: string;
    buyer_company_country_code?: string;
}

export interface IBuyerBillingAccount extends IBuyerMerchantRelationship {
    billing_account_id: string;
}

export interface IPeriodStatement {
    statement_id: string;
    statement_number: number;
    statement_suffix?: string;
    statement_reference: string;
    statement_creation_reason: string;
    issue_date: Date;
    due_date: Date;
    buyer_company_name?: string;
    payment_reference: string;
    total_amount: number;
    outstanding_amount: number;
    last_downloaded?: Date;
}

interface IPeriodTotals {
    invoiced_amount: number;
    paid_amount: number;
    credited_amount: number;
    collected_amount: number;
    recoursed_amount: number;
    outstanding_amount: number;
    last_updated: Date;
}

export interface IPeriodDetailResponse {
    id: string;
    billing_account_id: string;
    start_date: Date;
    end_date: string;
    currency: CurrencyCodeEnum;
    totals: IPeriodTotals;
    statements: [IPeriodStatement];
    bank_account: PaymentBankDetails;
}

export interface IBuyerInvoiceResponse {
    buyer_company_country_code: CountryCode;
    buyer_company_name: string;
    buyer_company_organization_number: string;
    buyer_representative_name: string;
    currency: CurrencyCode;
    due_date: Date;
    gross_amount: number;
    invoice_date: Date;
    invoice_id: string;
    invoice_number: string;
    invoice_pis_link: string;
    merchant_company_name: string;
    merchant_website?: string;
    order_id: string;
    payment_status: string;
    unreconciled_amount: number;
}

export interface ICursorPage {
    previous_page_cursor?: string;
    next_page_cursor?: string;
    limit: number;
    items?: [];
}

export interface IBillingStatement {
    statement_id: string;
    statement_number: number;
    buyer_company_name: string;
    start_date: Date;
    end_date: Date;
    issue_date: Date;
    payment_status: paymentStatus;
    payment_reference: string;
    total_amount: number;
    last_downloaded?: Date;
    statement_type?: string;
    due_date?: Date;
    currency: CurrencyCode;
    unreconciled_amount?: number;
    statement_pis_link?: string;
    trade_names?: string[];
    billing_account_id: string;
    billing_period_id: string;
}

export interface IBillingStatements {
    statements: IBillingStatement[];
    cursor_page_metadata?: ICursorPage;
}

export interface ICustomersResponse {
    customers: ICustomer[];
    cursor_page_metadata?: ICursorPage;
}

export interface IRepaymentPlansResponse {
    plans: IRepaymentPlan[];
    cursor_page_metadata?: ICursorPage;
}

export interface ICustomerRecourseLimitMaxAllowed {
    buyer_max_allowed_recourse_limit?: number;
}

export interface IBillingStatementFilters {
    downloaded?: boolean;
    due_date_from?: Date;
    due_date_to?: Date;
    payment_statuses?: string[];
}

interface ICompanyBasic {
    legal_name?: string;
    national_identification_number?: string;
    country_code?: string;
}

export interface IInvoiceStatementRequest {
    file_type: string;
    merchant_id?: string;
    buyer_companies?: ICompanyBasic[];
    start_date?: Date;
    end_date?: Date;
    payment_status?: string;
    billing_account_ids?: string[];
}

enum SelectorLabelTypeEnum {
    // Selector component label type is helpful for tab indexing on forms
    "DEFAULT",
    "BUTTON"
}

type ISelectorLabelType = keyof typeof SelectorLabelTypeEnum;

export interface IGraphStackedBarData {
    label: string;
    data: number[];
    backgroundColor: string;
    stack: string; // stack identifier name
    fill: boolean;
    borderRadius: number;
}

export {
    CurrencyCodeEnum,
    LanguageCode,
    OrderState,
    OrderType,
    InvoiceType,
    NotificationType,
    OrderOrigination
};

export type {
    IOrder,
    INavItem,
    IIssue,
    IPaid,
    IDelivery,
    IPayment,
    IPaymentList,
    ITransaction,
    ILogin,
    IAuthUser,
    INotification,
    IUsers,
    ISort,
    IMorder,
    IMOrderPaymentStatus,
    IBorder,
    IBuyerDetails,
    IMorderDetail,
    IMorderSummary,
    IOrderStatus,
    IOrderItem,
    ICustomer,
    ICustomerRecourseLimit,
    IAccount,
    IRepaymentPlan,
    IMerchantFlags,
    IStatement,
    ICreditInsights,
    ICreditAlert,
    IAuth,
    IBorderSummary,
    IFrame,
    IBMorder,
    IVerificationConfig,
    IFeaturesList,
    IMUserRoles,
    ITeamUser,
    IMNewUserConfig,
    IDashboardData,
    IDashboardChartValue,
    MerchantB2bOnboardingData,
    MerchantB2bOnboardingSuccess,
    MerchantB2bOnboardingFailure,
    IGroupedStatement,
    IQuote,
    IAllOrders,
    IPaymentInitiationPaymentDetails,
    IPaymentInitiationPaymentResponse,
    IPaymentDetails,
    IPaymentAuthorisationResponse,
    CountryCode,
    IMarket,
    ICountry,
    ICreditLimit,
    ICreditLimitResult,
    IUpdateEmailStatementSettings,
    InputType,
    IAccountFieldImage,
    IAccountField,
    IMerchantOrderInvalidLineItems,
    ISelectorLabelType,
    IInvoice,
    IPayee,
    ISelectorInputField,
    IBuyerLanguage,
    IDashboardOrderStateData,
    IMerchantRecourseLimit,
    IMerchantRemainingRecourseLimit,
    IMerchantUnreconciledInvoiceAmount,
    IMerchantUnreconciledInvoiceAmountOutdated,
    IMerchantRecourseLimitConfig,
    IRefundSurcharge,
    ISignupAccount,
    IAddress,
    IBanner,
    IAccountSection,
    IGridSection,
    MerchantPeriodBuyerOverview,
    IMerchantPeriodFulfilledOverviewResponseSchema,
    IMerchantPeriodReportGraphData
};

/**
  Component Library Types
  Move to Storybook?
 */
export enum CardWidths { // Rename to something more generic?
    "sm" = "314px", // Change
    "md" = "514px",
    "lg" = "714px" // Change
}
