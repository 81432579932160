<script lang="ts">
    import { _ } from "svelte-i18n";
    import { createEventDispatcher } from "svelte";
    import { Button } from "@two-ui/core";
    import Fa from "svelte-fa";
    import { faRocket } from "@fortawesome/free-solid-svg-icons";
    import { navigate } from "svelte-routing";

    import Modal from "@/components/Elements/Modal.svelte";
    import environment from "@/services/environment";

    export let showModal = false;

    const GET_STARTED_LINK = "https://forms.gle/LB16qeDSy3y5uKdG6";

    const dispatch = createEventDispatcher();

    function getStarted() {
        window.open(GET_STARTED_LINK, "_blank");
    }

    function closeModal() {
        showModal = false;
        dispatch("close");
    }
</script>

<Modal bind:show={showModal} on:close={closeModal} title={$_("order.new.limits.readyForNewWay")}>
    <div class="mt-6">
        <div>
            <p>
                {$_("order.new.limits.letYourCustomersPay", {
                    values: { companyName: environment.branding.displayName }
                })}
            </p>
        </div>
        <div class="text-Content-Semantic-Success-Secondary text-sm info-section">
            <Fa size="md" icon={faRocket} color="var(--ctnSemanticSuccessSecondary)" />
            <ol>
                <li>{$_("order.new.limits.letCustomersSplit")}</li>
                <li>{$_("order.new.limits.getEvenBiggerCredit")}</li>
                <li>{$_("order.new.limits.giveCustomersMoreRepayment")}</li>
            </ol>
        </div>
    </div>
    <div slot="footer" class="flex gap-2 mt-14">
        <Button
            size="md"
            variant="secondaryGray"
            content={$_("order.new.limits.goBack")}
            on:click={closeModal}
        />
        <Button
            size="md"
            variant="primary"
            content={$_("order.new.limits.getStarted")}
            on:click={getStarted}
        />
    </div>
</Modal>

<style lang="scss">
    .info-section {
        display: flex;
        padding: 8px;
        align-items: center;
        gap: 1rem;
        margin-top: 1rem;
        font-weight: 500;
        background: var(--bgSemanticSuccessTertiary);
    }
</style>
